<template>
  <!-- 客户案例-->
  <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="pic1">
        <img class="img" :src="img1">
      </div>
      <div class="link">
        <div class="title">
            <div class="title-big">经典客户案例</div>
            <div class="title-small">我们用心服务每一位客户</div>
        </div>
        <div class="forms1">
            <div class="form1" v-for="form in forms1" :key="form.title"
              @click="select(form)"
            >
              <div class="form1_icon">
                  <img :src="form.img">
              </div>
              <div class="form1_content">
                  <div class="form1_title">{{form.title}}</div>
                  <div class="form1_text">{{form.text}}</div>
                  <div class="form1_know">
                    <div class="know">了解更多</div>
                    <div class="img2">
                      <img :src="img2">
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
      <Record></Record>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Record from '../components/record.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'page5',
  components: {
    Header,
    Record,
    Footer
  },
  data () {
    return {
      img1: require('@/assets/img/page10.1.png'),
      forms1: [{
        id: '01',
        img: require('@/assets/img/page10.2.1.png'),
        title: '企淘猫',
        text: '企淘猫是一款查园区查企业的小程序，可以购买…',
        case_content: '企淘猫是一款查园区查企业的小程序，可以购买园区产品/服务，可以参加预约活动。主要目标用户是企业或者是想创建起的用户，主导他们选择园区和选择服务',
        case_img1: require('@/assets/img/企淘猫.jpg'),
        case_img2: require('../assets/img/case1.png')
      }, {
        id: '02',
        img: require('@/assets/img/page10.2.2.png'),
        title: '六大空间官网',
        text: '有6个空间，老师可在不同空间会发布不同内容…',
        case_content: '六大空间网站主要实用群体是老师，老师可以在网站发布文章，文章同一由后台审核，不同老师负责的板块不一样，具体权限由后台控制。',
        case_img1: require('@/assets/img/六大空间.png'),
        case_img2: require('../assets/img/case2.png')
      }, {
        id: '03',
        img: require('@/assets/img/page10.2.3.png'),
        title: '意大利红酒商城',
        text: '线上商城售卖红酒，可拼团、可分销、可促销…',
        case_content: '意大利红酒商城是小程序商城，包含基本商城功能以及一些营销功能，基本功能包括下单、订单管理、商品浏览，营销功能包含拼团、满减、优惠券等等',
        // case_img1: require('@/assets/img/意大利红酒.jpg'),
        case_img2: require('../assets/img/case3.png')
      }, {
        id: '04',
        img: require('@/assets/img/page10.2.4.png'),
        title: '地球奇侠',
        text: '购买课程线上学习课程，还可以预定门票场地…',
        case_content: '地球奇侠主要用户群体是需要购买线上视频课程的人，他们可以线上购买课程进行学习，还可以参与课程考试，考试通过后即可获得该课程的证书',
        // case_img1: require('@/assets/img/地球奇侠.jpg'),
        case_img2: require('../assets/img/case4.png')
      }, {
        id: '05',
        img: require('@/assets/img/page10.2.5.png'),
        title: '酒店店管理系统',
        text: '多语言版本切换，实现餐饮商店订房一体化操作…',
        case_content: '酒店管理系统支持多门店，支持住宿办理、购买商品、点餐，实现一体化管理，另外还有报表管理，日报月表都有二十多张报表详细记录每个账务数据',
        case_img1: require('@/assets/img/酒店管理系统.png'),
        case_img2: require('../assets/img/case5.png')
      }, {
        id: '06',
        img: require('@/assets/img/page10.2.6.png'),
        title: '闵教之家',
        text: '管理学校管理老师，发布福利创建活动，让教职…',
        case_content: '闵教之家是一款针对教职工使用的一个应用，目前已在接入随身办，教职工可以在应用上参与活动，参与福利抽奖，查看新闻，申请援助等',
        case_img1: require('@/assets/img/闵教之家.jpg'),
        case_img2: require('../assets/img/case6.png')
      }, {
        id: '07',
        img: require('@/assets/img/page10.2.7.png'),
        title: '云营销',
        text: '连接商户和云销售，为双方提供一站式业务撮合…',
        case_content: '云营销主要帮助企业招募云销售，实现企业业绩增加，云销售可以提交业绩，企业可以在后台制定奖励规则，给云销售结算等等。',
        case_img1: require('@/assets/img/云营销.png'),
        case_img2: require('../assets/img/case7.png')
      }, {
        id: '08',
        img: require('@/assets/img/page10.2.8.png'),
        title: '老兵回家',
        text: '老兵回家是一款展示老兵信息，救助困难老兵的…',
        case_content: '老兵回家主要是收集老兵资料、展示老兵信息、给老兵捐款，让人们可以铭记那些老兵，还活着的老兵可以帮助他们渡过难关。',
        case_img1: require('@/assets/img/老兵回家.jpg'),
        case_img2: require('../assets/img/case8.png')
      }],
      data: [],
      img2: require('@/assets/img/page10.3.png')
    }
  },
  methods: {
    select (form) {
      const data = JSON.stringify(form)
      this.$router.push({
        path: `/case/${form.id}`,
        query: {
          data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  z-index: 1;
  margin: 0.42rem -0.08rem 0 -0.08rem;
}
.pic1 {
  height: 1.98rem;
  margin-bottom: 0.2rem;
}
img {
 width: 100%;
}
.link {
  text-align: center;
  .title {
    margin-bottom: 0.2rem;
    .title-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title-small {
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .forms1 {
    margin: 0.16rem 0.16rem 0.2rem 0.16rem;
    .form1 {
      position: relative;
      display: flex;
      align-items: center;
      height: 0.74rem;
      box-sizing: border-box;
      margin-bottom: 0.08rem;
      border-radius: 0.03rem;
      box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
      .form1_icon {
        width: 0.74rem;
      }
      .form1_content {
        text-align: start;
        margin: 0.08rem 0 0.08rem 0.12rem;
        .form1_title {
          margin-top: 0.08rem;
          font-family: PingFangSC-Medium;
          font-size: 0.13rem;
          margin-bottom: 0.02rem;
        }
        .form1_text {
          font-family: PingFangSC-Regular;
          font-size: 0.11rem;
          color: #888888;
          padding-bottom: 0.06rem;
        }
        .form1_know {
          display: flex;
          align-items: center;
          margin-bottom: 0.12rem;
          .know {
            font-family: PingFangSC-Regularv;
            color: #3974FB;
            margin-right: 0.04rem;
          }
          .img2 {
            width: 0.045rem;
            padding-bottom: 0.02rem;
          }
        }
      }
    }
  }
}
</style>
